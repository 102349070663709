.main-container{
    width: 100%;
}

.introduction{
    text-align: center;
}

.central-container{
    width: 50%;
    min-width: 300px;
    max-width: 500px;
    margin: auto;
}
.card-title{
    margin-bottom: 0;
}

.preference-body{
    padding-top:0;
}

.button-div{
    text-align: center;
}